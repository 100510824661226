.language-selector {
    height: 100%;
    width: 100%;
    display: table;

    p {
        text-align: center;
        vertical-align: middle;
        display: table-cell;   
    }
}