.team-page {
    display: flex;
    flex-direction: column;
    text-align: center;

    h1 {
        font-size: 3rem;
    }

    .members-list {
        display: flex;
        flex-direction: column;
    }

    button {
        justify-content: center;
        padding: 1rem;
        margin: 1rem;
        background-color: rgb(34, 33, 33);
    }
    
    button:hover {
        background-color: rgb(0, 0, 0);
    }
}