.bx--header {
    background-color: $ui-01 !important;
    border-bottom: none !important;

    p, a {
        color: black !important;
    }
}

.bx--header__menu-trigger > svg {
    fill: $text-01 !important;
}

.bx--header__action > svg {
    fill: $text-01 !important;
}

.bx--header__menu-item {
    color: $text-01 !important;
}

.bx--header__menu-item:hover,
.bx--header__menu-trigger:hover,
.bx--header__action:hover {
    background-color: $ui-01 !important;
    // background-color: $inverse-support-04 !important;
    // color: blue !important;
}

.bx--header__menu-item:focus {
    border: 2px solid black !important;
}


.bx--assistive-text {
    visibility: collapse !important;
}

.bx--side-nav--expanded {
    width: auto !important;
}

