.member-tile {
    display: flex;
    align-self: center;

    .medals {
        position: absolute;
        top: 0;
        left: 0;

        .medal {
            height: 1.5rem;
            width: 1.5rem;
            margin-right: 0.5rem;
        }
    }

    
    .member-info {
        display: flex;
        
        .country-flag {
            width: 2.5rem !important;
            height: 5rem !important;
            margin: 0.5rem;
        }

        p, h2 {
            width: 100%;
            align-self: center;
            margin-bottom: 0.5rem;
        }
    }

    .member-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        p {
            margin-left: 1rem;
        }
    }
}
