@import 'carbon-components/scss/globals/scss/vars.scss';

@import './components/LanguageSelector/language-selector.scss';
@import './components/PageHeader/page-header.scss';
@import './components/ImageText/image-text.scss';
@import './components/Footer/footer.scss';
@import './components/MemberTile/member-tile.scss';

@import './content/HomePage/home-page.scss';
@import './content/TeamPage/team-page.scss';

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.App-logo {
  max-width:  40vw;
  max-height: 40vh;
  align-self: center;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    // animation: App-logo-spin infinite 20s linear;
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}