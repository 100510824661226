.footer {
    background-color: $ui-01;
    margin-top: auto;
    padding: 1rem;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    .links {
        justify-content: inherit;
        display: flex;
        flex-wrap: wrap;
    }
    
    .social {
        display: flex;
    }
}

